.wrapper {
  position: relative;
  background-color: var(--red);
}

.container {
  display: flex;
  gap: 16%;
}

.col {
  width: 42%;
  padding: 200px 10% 100px;
  background-color: var(--light-grey);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.imageContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.image {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .container {
    gap: 10%;
  }

  .col {
    width: 45%;
    padding: 100px 5% 100px;
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 0;
  }

  .col {
    width: 100%;
  }
}
